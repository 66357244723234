<template>
  <modal :show.sync="Modal"
         class="modal-black" centered :appendToBody="false"
         modalContentClasses="card"
         headerClasses="card-header">

    <div slot="header">
      <!-- <i class="fas fa-users fa-3x"></i>-->
      <h5 class="card-category">{{Categoria}}</h5>
      <h3 class="card-title">{{Titulo}}</h3>
      <span class="text-muted">{{Ayuda}}</span>
    </div>

    <template>

      <ValidationObserver v-slot="{ handleSubmit}">
        <form @submit.prevent="handleSubmit(guardar)">
          <MazInput v-model="datos.linkID"
                    placeholder="Identificador"
                    :autocomplete="autocompleteLinkID" :disabled="loading || cargando"
                    class="maz-mb-3" :loading="loading || cargando"
                    clearable>
            <template v-slot:icon-left>
              <i class="fas fa-key"></i>
            </template>
          </MazInput>
          <MazInput v-model="datos.etiqueta"
                    placeholder="Etiqueta"
                    :autocomplete="autocompleteEtiqueta" :disabled="loading || cargando"
                    class="maz-mb-3" required :loading="loading || cargando"
                    clearable>
            <template v-slot:icon-left>
              <i class="fas fa-tag"></i>
            </template>
          </MazInput>
          <MazInput v-model="datos.descripcion" class="maz-mb-3"
                    placeholder="Comentarios" :loading="loading || cargando"
                    :autocomplete="autocompleteComentario" :disabled="loading || cargando"
                    left-icon-name="comment">
            <template v-slot:icon-left>
              <i class="fas fa-tag"></i>
            </template>
          </MazInput>

          <collapse :multiple-active="false" :active-index="-1" class="mb-0">
            <collapse-item title="Capturar Dirección">
              <MainDireccionComp v-model="datos.dDireccion" size="sm" :cargando="loading || cargando"
                                 />
            </collapse-item>
          </collapse>
          <base-checkbox v-model="datos.esSucursal"><h5 class="text-muted">{{datos.esSucursal?"Si":"No"}} es sucursal</h5></base-checkbox>
          <hr class="my-md-4" />
          <base-button v-if="!cargando && cargadoID!=''" native-type="submit" slot="footer" type="primary" block>
            Guardar
          </base-button>

          <div class="text-muted text-center">

            <p v-if="Erroneo" class="badge badge-danger mr-2 text-wrap">
              {{ Erroneo }}
            </p>
            <div v-if="cargando" class="text-center mt-7">
              <b-spinner type="grow" label="cargando..."></b-spinner>
              Cargando...
            </div>
            <a href="#" class="d-block mt-2" @click="Cerrar()">
              <i class="fas fa-chevron-left"> </i> Cancelar
            </a>

          </div>
        </form>
      </ValidationObserver>
    </template>

  </modal>
</template>

<script>
  import Servicio from '@/api-services/ubicaciones.service';
  import MainDireccionComp from '@/Vistas-base/Main/Direccion.comp.vue';
  import { Collapse, CollapseItem } from 'src/components';
  export default {
    name: 'Ubicaciones-Edicion',
    components: {
      MainDireccionComp,
      Collapse, CollapseItem
    },
    props: {
      value: Object,
      Mostrar: {
        type: Boolean,
        default: false,
        description: "Indica si se mostrará"
      },
      Categoria: { type: String, default: 'Ubicaciones' },
      Titulo: { type: String, default: 'Edición' },
      Ayuda: { type: String, default: 'Las ubicaciones son lugares donde se esta realizando ciertos trabajos, como es el caso de las sucursales.' },
      //Muestra que esta cargando
      loading: { type: Boolean, default: false },
    },
    data() {
      return {
        datos: {

        },

        Modal: false,
        cargando: false,
        cargadoID: '',

        Erroneo: ''
      }
    },
    computed: {
      autocompleteLinkID() {
        return `${this.$options.name}-LinkID`;
      },
      autocompleteEtiqueta() {
        return `${this.$options.name}-Etiqueta`;
      },
      autocompleteComentario() {
        return `${this.$options.name}-Comentario`;
      },
      Config() {
        {
          return {
            labelKey: this.campoEtiqueta,
            searchKey: this.campoEtiqueta,
            valueKey: this.campoValor
          };
        }
      },
    },

    watch: {
      value(nuevoValor) {
        this.datos = Object.assign({}, nuevoValor);
        console.debug(this.datos);
      },
      Mostrar(Mostrar) {

        this.$appInsights.trackEvent({ name: this.$options.name }, { Mostrar: Mostrar });
        if (Mostrar) {
          this.Modal = true;
          if (this.cargadoID != this.datos.id) {
            this.cargadoID = "";
            Servicio.get(this, this.datos.id, (resonse) => {
              this.cargadoID = this.datos.id;
              this.datos = resonse.data;
            }, () => {
              this.Modal = false;
            });

          }
          else
            this.Modal = true;
        }
        else
          this.Modal = false;
      },
      Modal(Modal) {
        if (!Modal)
          this.$nextTick(() => { this.$emit('update:mostrar', Modal); });
      }
    },
    mounted() { this.Modal = this.Mostrar; this.datos = Object.assign({}, this.value);
      this.CargarDatos();
    },
    created() {
    },
    methods: {
      CargarDatos() {
        if (this.cargadoID != this.datos.id) {
          this.cargadoID = "";
          Servicio.get(this, this.datos.id, (response) => {
            this.cargadoID = this.datos.id;
            var Datos = response.data;
            console.debug(Datos);
            //Datos.adminsIDs = [];
            this.datos = Datos;
          }, () => {
            this.Modal = false;
          });

        }
      },
      cargaVacio() {
        this.datos = Object.assign({}, {
          etiqueta: '',
          comentarios: '',
          esSucursal: true,
          dDireccion: {},
          regionID: '',
        });
      },
      guardar() {
        Servicio.put(this, this.datos.id, this.datos, (response) => {
          this.$emit('guardado', response.data);

          this.Cerrar();

        }, (MsgError, error) => {
          this.Erroneo = MsgError;
        }, () => {
        });
      },
      Cerrar() {
        this.cargaVacio();
        this.Modal = false;
      },
    }
  }
</script>

<style scoped>
</style>
