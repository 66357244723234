export default {
    GoogleAddress2Direccion(GoogleAddress) {
        var Direccion = {};
        for (var i = 0; i < GoogleAddress.address_components.length; i++) {
            var Component = GoogleAddress.address_components[i];
            for (var t = 0; t < Component.types.length; t++) {
                switch (Component.types[t]) {
                    case "route":
                        Direccion.dirCalle = Component.long_name;
                        break;
                    case "street_number":
                        Direccion.dirCalleNo = Component.long_name;
                        break;
                    case "country":
                        Direccion.pais = Component.short_name;
                        break;
                    case "sublocality":
                        Direccion.colonia = Component.long_name;
                        break;
                    case "locality":
                        Direccion.ciudad = Component.long_name;
                        break;
                    case "administrative_area_level_1":
                        Direccion.estado = Component.long_name;
                        break;
                    case "postal_code":
                        Direccion.cp = Component.long_name;
                        break;
                }
            }
        }
        return Direccion;
    },
    sDireccion2Direccion(sDireccion) {
        if (sDireccion != undefined && sDireccion != null && sDireccion != "" && sDireccion != "{}") {
            var Direccion = JSON.parse(sDireccion);
            /* this.Pais = Direccion.Pais;
             this.DirCalle = Direccion.DirCalle;
             this.DirCalleNo = Direccion.DirCalleNo;
             this.DirInterior = Direccion.DirInterior;
             this.Colonia = Direccion.Colonia;
             this.Municipio = Direccion.Municipio;
             this.Ciudad = Direccion.Ciudad;
             this.Estado = Direccion.Estado;
             this.CP = Direccion.CP;
             */
            return Direccion;
        }

        return { Pais: "", DirCalle: "", DirCalleNo: "", DirInterior: "", Colonia: "", Municipio: "", Ciudad: "", Estado: "", CP: "", };
    },
    Direccion2SDireccion(Direccion) {
        return JSON.stringify(Direccion);
    },
    sDireccion2Texto(sDireccion) {
        if (sDireccion != undefined && sDireccion != null && sDireccion != "" && sDireccion != "{}")
            return this.Direccion2Texto(this.sDireccion2Direccion(sDireccion));
        return "";
    },
    Direccion2Texto(Direccion) {
       // console.debug(Direccion);
        if (Direccion == undefined || Direccion == null)
            return Direccion;

        var Texto = "";
        if (Direccion.DirCalle != undefined && Direccion.DirCalle != null)
            Texto = Direccion.DirCalle;
        if (Direccion.DirCalleNo != undefined && Direccion.DirCalleNo != null)
            Texto = `${Texto} ${Direccion.DirCalleNo}`;
        if (Direccion.DirInterior != undefined && Direccion.DirInterior != null)
            Texto = `${Texto} ${Direccion.DirInterior}`;

        if (Direccion.Colonia != undefined && Direccion.Colonia != null)
            Texto = `${Texto}, ${Direccion.Colonia}`;
        if (Direccion.Municipio != undefined && Direccion.Municipio != null)
            Texto = `${Texto}, ${Direccion.Municipio}`;

        if (Direccion.Estado != undefined && Direccion.Estado != null)
            Texto = `${Texto}, ${Direccion.Estado}`;
        if (Direccion.Pais != undefined && Direccion.Pais != null)
            Texto = `${Texto}, ${Direccion.Pais}`;
        if (Direccion.CP != undefined && Direccion.CP != null)
            Texto = `${Texto}, ${Direccion.CP}`;
        for (var i = 0; i < 4; i++)
            Texto = Texto.replace(", , ", ", ");
        Texto = Texto.trim();
    //    console.debug(`${Texto}/${Texto.indexOf(", ")}`);
        if (Texto.indexOf(", ") == 0)
            if (Texto.length == 2)
                Texto = "";
            else
                Texto = Texto.substring(2);
        if (Texto == ",")
            Texto = "";
        if (Texto.length > 1 && Texto[Texto.length - 1] == ",")
            Texto = Texto.substring(0, Texto.length - 1);
        //console.debug(Texto);
        return Texto;
    }
};
