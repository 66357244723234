<template>
  <div>
    <ValidationObserver v-slot="{ handleSubmit}">
      <form @submit.prevent="handleSubmit(guardar)">
        <div class="row">

          <div class="col-md-4 p-1 ">
            <div class="card p-3">
              <div slot="header">
                <!-- <i class="fas fa-users fa-3x"></i>-->
                <h5 class="card-category">{{Categoria}}</h5>
                <h3 class="card-title">{{Titulo}}</h3>
                <span class="text-muted">{{Ayuda}}</span>
              </div>
              <MazInput v-model="datos.linkID"
                        placeholder="Identificador"
                        :autocomplete="autocompleteLinkID" :disabled="loading || cargando"
                        class="maz-mb-3" :loading="loading || cargando"
                        clearable>
                <template v-slot:icon-left>
                  <i class="fas fa-key"></i>
                </template>
              </MazInput>
              <MazInput v-model="datos.etiqueta"
                        placeholder="Etiqueta"
                        :autocomplete="autocompleteEtiqueta" :disabled="loading || cargando"
                        class="maz-mb-3" required :loading="loading || cargando"
                        clearable>
                <template v-slot:icon-left>
                  <i class="fas fa-tag"></i>
                </template>
              </MazInput>
              <MazInput v-model="datos.descripcion" class="maz-mb-3"
                        placeholder="Comentarios" :loading="loading || cargando"
                        :autocomplete="autocompleteComentario" :disabled="loading || cargando"
                        left-icon-name="comment"
                        textarea />
              <MazInput v-model="datos.direccionEtiqueta" class="maz-mb-3"
                        placeholder="Dirección a mostrar" :loading="loading || cargando"
                        :autocomplete="autocompleteDireccion" :disabled="loading || cargando"
               
                        textarea>
                <template v-slot:icon-left>
                  <i class="fas fa-map"></i>
                </template>
              </MazInput>
              <MazInput v-model="datos.urlMaps"
                        placeholder="Liga a google Maps"
                        :autocomplete="autocompleteGMaps" :disabled="loading || cargando"
                        class="maz-mb-3" required :loading="loading || cargando"
                        clearable>
                <template v-slot:icon-left>
                  <i class="fas fa-map-marked"></i>
                </template>
              </MazInput>
              <MenuConfig v-model="datos.menuEntrada" Categoria="Menú entrada" Titulo="Opciones"/>
            </div>
          </div>
          <div class="col-md-8 p-1 ">
            <div class="card p-3">
              <div class="card shadow border-0 mb-0">
                <div id="acceso-map-canvas" class="acceso-map-canvas" style="height: 300px;"></div>
              </div>

              <div class="d-flex justify-content-end pb-1">
                <div class="w-100 text-center">
                  <small>
                    Precisión {{Precision}}m
                  </small>
                  <slider v-model="Precision" type="success" :connect="false" :range="{min: 5, max: 500}" :options="Opciones"></slider>
                </div>
                <base-button primary size="sm" type="secondary" class="" v-on:click="Direccion2GPS">
                  <i class="fas fa-caret-up"> </i>
                </base-button>
                <base-button primary size="sm" type="secondary" v-on:click="GPS2Direccion">
                  <i class="fas fa-caret-down"> </i>
                </base-button>
              </div>
              <MainDireccionComp v-model="datos.dDireccion" size="sm" :cargando="loading || cargando" class="px-3" />
            </div>
          </div>

          <hr class="my-md-4" />
          <div class="col-12 text-center">
            <base-button v-if="!cargando" @click="OnImprimir">
              <i class="fas fa-print mr-2"></i> Imprimir QR
            </base-button>
            <base-button v-if="!cargando && ID" native-type="submit" type="primary">
              Guardar
            </base-button>

            <div class="text-muted text-center">

              <p v-if="Erroneo" class="badge badge-danger mr-2 text-wrap">
                {{ Erroneo }}
              </p>
              <div v-if="cargando" class="text-center mt-7">
                <b-spinner type="grow" label="cargando..."></b-spinner>
                Cargando...
              </div>
              <a href="#" class="d-block mt-2" @click="Cerrar()">
                <i class="fas fa-chevron-left"> </i> Cancelar
              </a>

            </div>
          </div>
        </div>

        <QRImpresion class="d-none" ref="sImpresion" v-model="linkID" :Etiqueta="datos.etiqueta" :Descripcion="datos.descripcion" />
      </form>
    </ValidationObserver>

  </div>
</template>

<script>
  import SuscripcionesUsuarios from '@/api-base/suscripciones.usuarios.service';
  import Referencias from '@/api-base/referencias.service';
  import Imprimir from '@/api-base/imprimir';
  import Servicio from '@/api-services/ubicaciones.service';
  import MainDireccionComp from '@/Vistas-base/Main/Direccion.comp.vue';
  import { API_KEY } from '@/api-base/maps.apikey';
  import { Loader, LoaderOptions } from "google-maps";
  const loader = new Loader(API_KEY);
  import QRImpresion from './QR.Impresion.vue';
  const MenuConfig = () => import(/* webpackChunkName: "accesos" */ './Menu.config.vue');
  

  import {
    Slider,
  } from 'src/components/index';
  import Direcciones from '@/api-base/direcciones.service';


  export default {
    name: 'Ubicaciones-Edicion',
    components: {
      MainDireccionComp,
      Slider,
      QRImpresion,
      MenuConfig
    },
    props: {
      value: Object,
      Mostrar: {
        type: Boolean,
        default: false,
        description: "Indica si se mostrará"
      },
      Categoria: { type: String, default: 'Ubicaciones' },
      Titulo: { type: String, default: 'Edición' },
      Ayuda: { type: String, default: 'Las ubicaciones son lugares donde se esta realizando ciertos trabajos, como es el caso de las sucursales.' },
      //Muestra que esta cargando
      loading: { type: Boolean, default: false },
    },
    data() {
      return {
        datos: {

        },


        Precision: 30,


        Guardando: false,

        Opciones: {
          step: 1
        },

        Modal: false,
        cargando: false,
        ID: null,

        Map: null,
        Marker: {},
        Circle: null,

        GPS: { lat: 19.4270219, lng: -99.1681479, acc: 10 },

        Erroneo: '',
        linkID: null,
      }
    },
    computed: {
      autocompleteLinkID() {
        return `${this.$options.name}-LinkID`;
      },
      autocompleteEtiqueta() {
        return `${this.$options.name}-Etiqueta`;
      },
      autocompleteComentario() {
        return `${this.$options.name}-Comentario`;
      },
      autocompleteDireccion() {
        return `${this.$options.name}-Direccion`;
      },
      autocompleteGMaps() {
        return `${this.$options.name}-GMaps`;
      },
      Config() {
        {
          return {
            labelKey: this.campoEtiqueta,
            searchKey: this.campoEtiqueta,
            valueKey: this.campoValor
          };
        }
      },
    },

    watch: {
      Precision: function (val) {

        this.mPrecision = val;
        if (this.Circle != null) {
          //  this.ActualizaPin();
          this.Circle.setRadius(parseFloat(this.mPrecision));
        }
        this.GPS.acc = val;
      },
      value(nuevoValor) {
        this.datos = Object.assign({}, nuevoValor);
        console.debug(this.datos);
      },
      Mostrar(Mostrar) {

        this.$appInsights.trackEvent({ name: this.$options.name }, { Mostrar: Mostrar });
        if (Mostrar) {
          this.Modal = true;
          if (this.cargadoID != this.datos.id) {
            this.cargadoID = "";
            Servicio.get(this, this.datos.id, (resonse) => {
              this.cargadoID = this.datos.id;
              this.datos = resonse.data;
              if (this.datos.gps) {
                this.GPS = this.datos.gps;
                //  console.debug(this.GPS);
                let google = window.google;
                const NPos = new google.maps.LatLng(this.GPS.lat, this.GPS.lng);

                this.Marker.setPosition(NPos);
                //setTimeout(() => { this.Marker.setPosition(NPos); }, 2000);

                this.Circle.setCenter(NPos);
                this.Circle.setRadius(parseFloat(this.GPS.acc));
                this.Map.setCenter(NPos);
              }

            }, () => {
              this.Modal = false;
            });

          }
          else
            this.Modal = true;
        }
        else
          this.Modal = false;
      },
      Modal(Modal) {
        if (!Modal)
          this.$nextTick(() => { this.$emit('update:mostrar', Modal); });
      }
    },
    mounted() {
      this.Modal = this.Mostrar;
      if (this.$route.query.id != null || this.$route.params.id != null) {
        var ID = '';
        if (this.$route.query.id != null)
          ID = this.$route.query.id;
        if (this.$route.params.id != null)
          ID = this.$route.params.id;
        this.ID = ID;
        this.datos = Object.assign({}, this.value);
        this.CargarDatos();
      }

      var Yo = this;

      loader.load().then(function (google) {
        // Yo.CreaMapa();
        // let google = window.google;
        var DivMap = document.getElementById('acceso-map-canvas');
        console.debug(JSON.stringify(Yo.GPS));
        const NPos = new google.maps.LatLng(Yo.GPS.lat, Yo.GPS.lng);
        console.debug(NPos);
        console.debug(JSON.stringify(NPos));
        const mapOptions = {
          zoom: 16,
          scrollwheel: true,
          center: NPos,

          mapTypeId: google.maps.MapTypeId.ROADMAP,

        }
        Yo.Map = new google.maps.Map(DivMap, mapOptions);
        //map.center = NPos;
        Yo.Marker = new google.maps.Marker({
          position: NPos,
          map: Yo.Map,
          draggable: true,
          animation: google.maps.Animation.DROP,
          title: 'Ubicación'
        });



        //console.debug(this.mPrecision);
        Yo.Circle = new google.maps.Circle({
          center: Yo.Marker.position,
          radius: parseFloat(Yo.GPS.acc),
          map: Yo.Map,//your map,
          fillColor: '#FF0000',//color,
          fillOpacity: 0.35,//opacity from 0.0 to 1.0,
          strokeColor: '#FF0000', //stroke color,
          strokeOpacity: 0.8,//opacity from 0.0 to 1.0
          // animation: google.maps.Animation.DROP,
        });

        google.maps.event.addListener(Yo.Marker, 'drag', (event) => {
          console.debug('new position is ' + event.latLng.lat() + ' / ' + event.latLng.lng());
          Yo.Circle.setCenter(Yo.Marker.position);
          // this.Circle.center = this.Marker.position;
        });

        google.maps.event.addListener(Yo.Marker, 'dragend', (event) => {
          console.debug('final position is ' + event.latLng.lat() + ' / ' + event.latLng.lng());
          Yo.GPS.lat = event.latLng.lat();
          Yo.GPS.lng = event.latLng.lng();
          console.debug(Yo.GPS);
          Yo.Circle.setCenter(Yo.Marker.position);// new google.maps.LatLng(event.latLng.lat(), event.latLng.lng()));
        });
      });
    },
    created() {
    },
    methods: {
      OnImprimir() {
        var sImpresion = this.$refs.sImpresion;
        console.debug(sImpresion);

        Imprimir.PrintElem(sImpresion.$el.innerHTML);

      },
      CargarDatos() {

        Referencias.patch(this,
          {
            suscripcionID: SuscripcionesUsuarios.getSuscripcionID(),
            usuarioID: SuscripcionesUsuarios.SuscripcionesUsuario[0].usuarioID,
            extra: this.ID,
            tipoReferencia: 'Checada'
          }, (response) => {
            //  this.info = response.data;
            this.linkID = response.data.linkID;
            //console.debug(response.data);
          }, (MsgError) => { this.Erroneo = MsgError; });

        Servicio.get(this, this.ID, (response) => {

          var Datos = response.data;
          console.debug(Datos);
          //Datos.adminsIDs = [];
          this.datos = Datos;
          if (this.datos.gps) {
            try {
              this.GPS = this.datos.gps;
              //  console.debug(this.GPS);
              let google = window.google;
              const NPos = new google.maps.LatLng(this.GPS.lat, this.GPS.lng);

              this.Marker.setPosition(NPos);
              //setTimeout(() => { this.Marker.setPosition(NPos); }, 2000);

              this.Circle.setCenter(NPos);
              this.Circle.setRadius(parseFloat(this.GPS.acc));
              this.Map.setCenter(NPos);
              this.Precision = this.GPS.acc;
            } catch {

            }
          }
        }, () => {
          this.Modal = false;
        });


      },
      cargaVacio() {
        this.datos = Object.assign({}, {
          etiqueta: '',
          comentarios: '',
          esSucursal: true,
          dDireccion: {},
          regionID: '',
        });
      },
      guardar() {
        this.datos.gps = this.GPS;
     //   this.datos.dDireccion.gps = this.GPS;
        Servicio.put(this, this.datos.id, this.datos, (response) => {
          this.$emit('guardado', response.data);

          this.Cerrar();

        }, (MsgError, error) => {
          this.Erroneo = MsgError;
        }, () => {
        });
      },
      Cerrar() {
        //  this.cargaVacio();
        this.Modal = false;
        if (window.history.length > 2)
          this.$router.go(-1);
        else
          this.$router.go("/ubicaciones");
      },
      CreaMapa() {
        let google = window.google;
        this.Map = document.getElementById('acceso-map-canvas');
        console.debug(this.GPS);
        const NPos = new google.maps.LatLng(this.GPS.lat, this.GPS.lng);
        //console.debug(this.Map);
        const mapOptions = {
          zoom: 16,
          scrollwheel: true,
          center: NPos,

          mapTypeId: google.maps.MapTypeId.ROADMAP,

        }
        this.Map = new google.maps.Map(this.Map, mapOptions);

        this.Marker = new google.maps.Marker({
          position: NPos,
          map: this.Map,
          // draggable: true,
          animation: google.maps.Animation.DROP,
          title: 'Ubicación'
        });


        //console.debug(this.mPrecision);
        this.Circle = new google.maps.Circle({
          center: this.Marker.position,
          radius: parseFloat(1),
          map: this.Map,//your map,
          fillColor: '#FF0000',//color,
          fillOpacity: 0.35,//opacity from 0.0 to 1.0,
          strokeColor: '#FF0000', //stroke color,
          strokeOpacity: 0.8,//opacity from 0.0 to 1.0
          // animation: google.maps.Animation.DROP,
        });


      },
      Direccion2GPS() {
        let google = window.google
        var geocoder = new google.maps.Geocoder();
        var address = Direcciones.Direccion2Texto(this.Direccion);
        //   console.debug(address);
        geocoder.geocode({ 'address': address }, (results, status) => {
          if (status == 'OK') {
            var latLng = results[0].geometry.location;
            //this.Marker.position = latLng;
            this.Marker.setPosition(latLng);
            this.GPS.lat = latLng.lat();
            this.GPS.lng = latLng.lng();
            //console.debug(this.GPS);
            this.Circle.setCenter(latLng);
            this.Map.setCenter(latLng);


          } else {
            alert('Geocode was not successful for the following reason: ' + status);
          }
        });
      },

      GPS2Direccion() {
        let google = window.google
        var geocoder = new google.maps.Geocoder();
        var latlng = { lat: this.GPS.lat, lng: this.GPS.lng };
        console.debug(latlng);
        geocoder.geocode({ 'location': latlng }, (results, status) => {
          if (status === 'OK') {
            console.debug(results);
            if (results[0]) {
              var D = Direcciones.GoogleAddress2Direccion(results[0]);
              console.debug(D);
              this.datos.dDireccion = D;
              //         console.debug(this.Direccion);
              /*
              this.Marker.position = latlng;
              this.GPS.Lat = latLng.lat();
              this.GPS.Lng = latLng.lng();
              //console.debug(this.GPS);
              this.Circle.setCenter(this.Marker.position);
              this.Map.setCenter(this.Marker.position);
             /* map.setZoom(11);
              var marker = new google.maps.Marker({
                  position: latlng,
                  map: map
              });
              infowindow.setContent(results[0].formatted_address);
              infowindow.open(map, marker);*/
            } else {
              window.alert('No results found');
            }
          } else {
            window.alert('Geocoder failed due to: ' + status);
          }
        });

      }
    }
  }
</script>

<style scoped>
</style>
